import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/mdxLayout.tsx";
import BackToTopButton from '../components/BackToTopButton';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "1-introduction"
    }}>{`1. Introduction`}</h2>
    <p>{`PanSurg (`}<strong parentName="p">{`“we”`}</strong>{`, `}<strong parentName="p">{`“us”`}</strong>{`, `}<strong parentName="p">{`“our”`}</strong>{`) is committed to protecting the privacy of the users and visitors of our website. This Privacy Policy states our policy and—practices regarding the collection and use of information via our website.`}</p>
    <p>{`We want to provide a safe and secure user experience. We will ensure that the information you submit to us, or which we collect, via various channels (including our website or through written correspondence (including e-mail), is only used for the purposes set out in this Privacy Policy.`}</p>
    <p>{`Through this Privacy Policy we aim to inform you about the types of personal data we collect, the purposes for which we use the data and the ways in which the data is handled. We also aim to satisfy the obligation of transparency under the EU General Data Protection Regulation 2016/679 (`}<strong parentName="p">{`“GDPR”`}</strong>{`) and national laws implementing GDPR.`}</p>
    <p>{`For the purpose of this Privacy Policy the controller of personal data is PanSurg and our contact details are set out in the Contact section at the end of this Privacy Policy.`}</p>
    <BackToTopButton mdxType="BackToTopButton" />
    <h2 {...{
      "id": "2-the-information-we-collect"
    }}>{`2. The Information We Collect`}</h2>
    <p>{`In general, you can visit the PanSurg website without identifying yourself or revealing any personal information.`}</p>
    <p>{`However, you may choose to provide us with personally identifiable information. In some cases, this is required in order use our services from us. Personally identifiable information is information that can be used to identify you, such as your name, email address, telephone number, or similar information.`}</p>
    <p>{`The types of personal information collected on these pages includes some or all of the following:`}</p>
    <h3 {...{
      "id": "information-you-give-us-when-contacting-us-signing-up-to-our-mailing-lists-or-registering-interest-in-our-collaborative-projects"
    }}>{`Information you give us when contacting us, signing up to our mailing lists or registering interest in our collaborative projects`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Contact details:`}</strong>{` name, email address, telephone number, institution, role`}</li>
    </ul>
    <h3 {...{
      "id": "information-we-collect-automatically-when-you-use-our-website"
    }}>{`Information we collect automatically when you use our website:`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Technical information:`}</strong>{` IP address, domain names, country you’re visiting from, your web browser type and version, time-zone setting, browser plug-in types and versions, operating system and platform.`}</li>
      <li parentName="ul"><strong parentName="li">{`Visit information:`}</strong>{` information about the pages you visit on our website, how long you spend on each page, how you interact with page content (e.g. clicks, scrolls and mouse-overs) and any products or services you request.`}</li>
    </ul>
    <p>{`This information is collected in anonymously and helps us to optimise our website performance to meet your needs.`}</p>
    <BackToTopButton mdxType="BackToTopButton" />
    <h2 {...{
      "id": "3-how-we-use-your-personal-information"
    }}>{`3. How we use your Personal Information`}</h2>
    <p>{`Information that you provide voluntarily will only be used in connection with your relationship with PanSurg. We will hold, use and disclose your personally identifiable information to:`}</p>
    <ul>
      <li parentName="ul">{`contact you about news, events and collaborations in which you have expressed an interest;`}</li>
      <li parentName="ul">{`to administer our website;`}</li>
      <li parentName="ul">{`to keep our website and systems secure and prevent fraud;`}</li>
      <li parentName="ul">{`where relevant, to meet legal, regulatory or compliance needs;`}</li>
    </ul>
    <p>{`We will not use or share the personally identifiable information provided to us online in ways unrelated to the uses described in this statement. More information about how we may share your information can be found at section 5.`}</p>
    <BackToTopButton mdxType="BackToTopButton" />
    <h2 {...{
      "id": "4-the-legal-basis-for-processing-your-personal-information"
    }}>{`4. The Legal Basis for Processing your Personal Information`}</h2>
    <p>{`Under GDPR, the main grounds that we rely upon in order to process personal information of users and visitors of our websites are the following:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`(a) `}<strong parentName="p">{`Necessary for compliance with a legal obligation`}</strong>{` – we are subject to certain legal requirements which may require us to process your personal data. We may also be obliged by law to disclose your personal data to a regulatory body or law enforcement agency;`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`(b) `}<strong parentName="p">{`Necessary for the purposes of legitimate interests`}</strong>{` – we will need to process your personal data for the purposes of our legitimate interests, provided we have established that those interests are not overridden by your rights and freedoms, including your right to have your personal data protected. Our legitimate interests include responding to requests and enquiries from you, optimising our website and user experience, informing you about our services and ensuring that our operations are conducted in an appropriate and efficient manner;`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`(c) `}<strong parentName="p">{`Consent`}</strong>{` – in some circumstances, we may ask for your consent to process your personal data in a particular way.`}</p>
      </li>
    </ul>
    <BackToTopButton mdxType="BackToTopButton" />
    <h2 {...{
      "id": "5-how-we-share-your-personal-information"
    }}>{`5. How we share your Personal Information`}</h2>
    <p>{`We will only ever share your personal information with other parties to allow us to provide our service to you. For example, we use third parties to host our website and manage our databases`}</p>
    <p>{`Details of third parties with which we routinely share information for the purpose of delivering our products and services are set out below:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Web services:`}</strong>{` Netlify`}</li>
      <li parentName="ul"><strong parentName="li">{`Website analytics and search engine providers:`}</strong>{` Google Analytics`}</li>
      <li parentName="ul"><strong parentName="li">{`Companies that assist in our marketing and advertising activities`}</strong></li>
    </ul>
    <p>{`Wherever possible, personal information that we share with third parties will be in an anonymised and aggregated format from which individuals cannot be identified.`}</p>
    <p>{`Rarely, we may share your personal information with other parties in the following exceptional circumstances:`}</p>
    <ul>
      <li parentName="ul">{`We may disclose your personal information if required to do so by applicable law, regulation or as part of a legal process. If we receive a request from a regulatory body or law enforcement agency, and if permitted under GDPR and other laws, we may disclose certain personal information to such bodies or agencies.`}</li>
    </ul>
    <BackToTopButton mdxType="BackToTopButton" />
    <h2 {...{
      "id": "6-how-long-we-will-hold-your-information"
    }}>{`6. How long we will hold your information`}</h2>
    <p>{`We will hold your personal information for as long as PanSurg maintains an active web presence. We will erase your personal information at any time in response to a request from you.`}</p>
    <BackToTopButton mdxType="BackToTopButton" />
    <h2 {...{
      "id": "7-your-rights-on-information-we-hold-about-you"
    }}>{`7. Your rights on Information we hold about you`}</h2>
    <p>{`You have certain rights in relation to personal information we hold about you. Details of these rights and how to exercise them are set out below.  We will require evidence of your identity before we are able to act on your request.`}</p>
    <h3 {...{
      "id": "right-of-access"
    }}>{`Right of Access`}</h3>
    <p>{`You have the right at any time to ask us for a copy of the personal information about you that we hold.  Where we have good reason, and if the GDPR permits, we can refuse your request for a copy of your personal information, or certain elements of the request. If we refuse your request or any element of it, we will provide you with our reasons for doing so.`}</p>
    <h3 {...{
      "id": "right-of-correction-or-completion"
    }}>{`Right of Correction or Completion`}</h3>
    <p>{`If personal information we hold about you is not accurate, out of date or incomplete, you have a right to have the data rectified, updated or completed.  You can let us know by contacting us at `}<a parentName="p" {...{
        "href": "mailto:admin@pansurg.org",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`admin@pansurg.org`}</a>{`.`}</p>
    <h3 {...{
      "id": "right-of-erasure"
    }}>{`Right of Erasure`}</h3>
    <p>{`In certain circumstances, you have the right to request that personal information we hold about you is erased e.g. if the information is no longer necessary for the purposes for which it was collected or processed or our processing of the information is based on your consent and there are no other legal grounds on which we may process the information.`}</p>
    <h3 {...{
      "id": "right-to-object-to-or-restrict-processing"
    }}>{`Right to object to or restrict processing`}</h3>
    <p>{`In certain circumstances, you have the right to object to our processing of your personal information by contacting us `}<a parentName="p" {...{
        "href": "mailto:admin@pansurg.org",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`admin@pansurg.org`}</a>{`. For example, if we are processing your information on the basis of our legitimate interests and there are no compelling legitimate grounds for our processing which override your rights and interests. You also have the right to object to use of your personal information for direct marketing purposes.`}</p>
    <p>{`You may also have the right to restrict our use of your personal information, such as in circumstances where you have challenged the accuracy of the information and during the period where we are verifying its accuracy.`}</p>
    <h3 {...{
      "id": "right-of-data-portability"
    }}>{`Right of Data Portability`}</h3>
    <p>{`In certain instances, you have a right to receive any personal information that we hold about you in a structured, commonly used and machine-readable format.  You can ask us to transmit that information to you or directly to a third party organisation.`}</p>
    <p>{`The above right exists only in respect of personal information that:`}</p>
    <ul>
      <li parentName="ul">{`you have provided to us previously; and`}</li>
      <li parentName="ul">{`is processed by us using automated means.`}</li>
    </ul>
    <p>{`While we are happy for such requests to be made, we are not able to guarantee technical compatibility with a third party organisation’s systems. We are also unable to comply with requests that relate to personal information of others without their consent.`}</p>
    <p>{`You can exercise any of the above rights by contacting us at `}<a parentName="p" {...{
        "href": "mailto:admin@pansurg.org",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`admin@pansurg.org`}</a>{`.`}</p>
    <p>{`Most of the above rights are subject to limitations and exceptions.  We will provide reasons if we are unable to comply with any request for the exercise of your rights.`}</p>
    <p>{`To the extent that we are processing your personal information based on your consent, you have the right to withdraw your consent at any time.`}</p>
    <BackToTopButton mdxType="BackToTopButton" />
    <h2 {...{
      "id": "8-use-of-cookies"
    }}>{`8. Use of Cookies`}</h2>
    <p>{`“Cookies” are small text files placed on your device (e.g. computer, phone or tablet) when viewing certain pages on our website. Cookies allow us to keep track of some of your browsing preferences and optimise our website for your personal use. Cookies also allow us to automatically track certain information about how you navigate through, and interact with, our website, which helps us to measure our website’s performance and to improve its design and functionality.`}</p>
    <p>{`For more information on cookies, please visit `}<a parentName="p" {...{
        "href": "https://www.allaboutcookies.org",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`https://www.allaboutcookies.org`}</a>{`.`}</p>
    <p>{`We use the following cookies:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Strictly necessary cookies.`}</strong>{` These are cookies that are required for the operation of our website. They include, for example, cookies that enable you to log into your account or make use of e-billing services.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Analytical or performance cookies.`}</strong>{` These allow us to recognise and count the number of visitors and to see how visitors move around our website when they are using it. This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Functionality cookies.`}</strong>{` These are used to recognise you when you return to our website. This enables us to personalise our content for you and remember your preferences.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Targeting cookies.`}</strong>{` These cookies record your visit to our website, the pages you have visited and the links you have followed. We will use this information to make our website more relevant to your interests.`}</p>
      </li>
    </ul>
    <BackToTopButton mdxType="BackToTopButton" />
    <h2 {...{
      "id": "9-complaints"
    }}>{`9. Complaints`}</h2>
    <p>{`If you are unhappy about our use of your personal information, you can contact us at `}<a parentName="p" {...{
        "href": "mailto:admin@pansurg.org",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`admin@pansurg.org`}</a>{`. You are also entitled to lodge a complaint with the UK Information Commissioner’s Office using any of the below contact methods:`}</p>
    <p><strong parentName="p">{`Telephone:`}</strong>{` 0303 123 11113`}</p>
    <p><strong parentName="p">{`Website:`}</strong>{` `}<a parentName="p" {...{
        "href": "https://ico.org.uk/concerns/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`https://ico.org.uk/concerns/`}</a></p>
    <p><strong parentName="p">{`Post:`}</strong>{` Information Commissioner’s Office, Wycliffe House, Water Lane, Wilmslow, Cheshire, SK9 5AF`}</p>
    <BackToTopButton mdxType="BackToTopButton" />
    <h2 {...{
      "id": "10-security"
    }}>{`10. Security`}</h2>
    <p>{`We have put in place measures to ensure the security of the information we collect and store about you and will use our reasonable endeavours to protect your personal data from unauthorised disclosure and/or access including through the use of network and database security measures. Despite our best efforts, there is always some risk in storing and transferring information over electronic media and we cannot guarantee the absolute security of any data which is collected and stored. However, were such a data breach to occur, we do guarantee to act swiftly in an effort to minimise its effects.`}</p>
    <BackToTopButton mdxType="BackToTopButton" />
    <h2 {...{
      "id": "11-other-websites"
    }}>{`11. Other websites`}</h2>
    <p>{`Please note that clicking on links on our website can result in your browser accessing a third party website, where data privacy practices are different to that of PanSurg.`}</p>
    <p>{`We are not responsible for, and have no control over, information that is submitted or collected by these third parties and you should consult their privacy policies.`}</p>
    <BackToTopButton mdxType="BackToTopButton" />
    <h2 {...{
      "id": "12-internet-based-transfers"
    }}>{`12. Internet based transfers`}</h2>
    <p>{`Given that the Internet is a global environment, using the Internet to collect and process personal data necessarily involves the transmission of data on an international basis. Unfortunately, the transmission of information via the Internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to our site via third party networks; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access.`}</p>
    <BackToTopButton mdxType="BackToTopButton" />
    <h2 {...{
      "id": "13-changes-to-our-privacy-policy"
    }}>{`13. Changes to our Privacy Policy`}</h2>
    <p>{`Our Privacy Policy is subject to change from time to time and, if we update it, we will post these changes on this page so that you will be aware of how we use your information.`}</p>
    <BackToTopButton mdxType="BackToTopButton" />
    <h2 {...{
      "id": "14-contact-us"
    }}>{`14. Contact Us`}</h2>
    <p>{`If you have any enquires or if you would like to contact us about our processing of your personal information, including to exercise your rights as outlined above, please contact us centrally by any of the methods below. When you contact us, we will ask you to verify your identity.`}</p>
    <p><strong parentName="p">{`Contact name:`}</strong>{` PanSurg Admin`}</p>
    <p><strong parentName="p">{`Email:`}</strong>{` `}<a parentName="p" {...{
        "href": "mailto:admin@pansurg.org",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`admin@pansurg.org`}</a></p>
    <BackToTopButton mdxType="BackToTopButton" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      