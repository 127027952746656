import * as React from 'react'
import { graphql, StaticQuery } from 'gatsby'

export default ({ pageContext, children }) => (

<>

<section className="section is-small">
  <div className="container">
    <p className="title has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen is-spaced has-text-darkBlue">
    {pageContext.frontmatter.title}</p>
  </div>
</section>


    <section className="section is-small">
      <div className="container">
        <div className="content">{children}</div>
      </div>
    </section>

</>
)
